import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'

declare var Intercom: any

@Injectable({
  providedIn: 'root'
})
export class IntercomService {
  boot (): void {
    Intercom('boot', {
      app_id: environment.intercom.appId,
      user_id: 'ch__tz', // User ID
      user_hash: '5cdb6c2637433c3e1d75dd781bdce5a5b05d28da405a9242da2c9de8ed0e5ae2' // HMAC using SHA-256
    })
  }
}
