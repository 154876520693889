import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-for-non-profits-page',
  templateUrl: './for-non-profits-page.component.html',
  styleUrls: ['./for-non-profits-page.component.scss']
})
export class ForNonProfitsPageComponent implements OnInit {
  ngOnInit (): void {
  }
}
