<div class="uk-section">
    <div class="uk-container">

        <div>
            <h1 class="uk-heading-medium">Community Guidelines</h1>
        </div>

    </div>
</div>

<div class="uk-section">
    <div class="uk-container">

        <div>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur voluptas, eligendi, ipsa illum
                nemo ducimus minus iste sed deserunt dolore aliquid ipsum perferendis nam quia ea nulla necessitatibus
                quae voluptatibus!
            </p>
        </div>

    </div>
</div>