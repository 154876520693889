import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-for-individuals-page',
  templateUrl: './for-individuals-page.component.html',
  styleUrls: ['./for-individuals-page.component.scss']
})
export class ForIndividualsPageComponent implements OnInit {
  ngOnInit (): void {
  }
}
