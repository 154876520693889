<div class="uk-section uk-section-xlarge">
    <div class="uk-container">

        <div class="uk-margin-xlarge">
            <h1 class="uk-heading-small uk-text-center">Pricing</h1>
        </div>

        <div class="uk-child-width-1-2@s uk-flex" uk-grid>

            <div class="uk-text-center uk-flex-last@s">
                <div class="uk-margin">
                    <h1 style="font-size: 6rem;">$14</h1>
                </div>
                <div class="uk-margin">
                    <span class="uk-display-block uk-text-meta uk-text-uppercase uk-text-large">/month</span>
                </div>
            </div>

            <div class="uk-text-center uk-text-left@s">
                <div class="uk-margin">
                    <h1 class="uk-heading-small">Per User</h1>
                </div>
                <div class="uk-margin">
                    <p class="uk-text-large">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid est eaque amet optio neque
                        perferendis.
                    </p>
                </div>
                <div class="uk-margin-medium-top">
                    <a class="uk-button uk-border-rounded uk-button-primary" href="https://dev.app.fieldt.rip/sign-up"
                        target="_blank">APPLY NOW</a>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="uk-section uk-section-large uk-section-priamry">
    <div class="uk-container">
        <div class="uk-child-width-1-3@s uk-grid-large" uk-grid>

            <div class="uk-text-center">
                <div class="uk-margin">
                    <img class="uk-border-rounded" src="https://placehold.it/256x256" alt="">
                </div>
                <div class="uk-margin">
                    <h1 class="uk-text-lead">Header</h1>
                </div>
                <div class="uk-margin">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque quibusdam, ipsum dolore,
                        nobis cumque totam nesciunt facere iusto, ipsa molestiae numquam? Deserunt sit nesciunt fuga
                        molestias, odio ipsam recusandae a.
                    </p>
                </div>
            </div>

            <div class="uk-text-center">
                <div class="uk-margin">
                    <img class="uk-border-rounded" src="https://placehold.it/256x256" alt="">
                </div>
                <div class="uk-margin">
                    <h1 class="uk-text-lead">Header</h1>
                </div>
                <div class="uk-margin">
                    <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Autem, numquam! Iure, sequi at sit
                        voluptate officiis minus. Error aliquid id itaque laborum facere dicta? Aut nisi error similique
                        perferendis cupiditate!
                    </p>
                </div>
            </div>

            <div class="uk-text-center">
                <div class="uk-margin">
                    <img class="uk-border-rounded" src="https://placehold.it/256x256" alt="">
                </div>
                <div class="uk-margin">
                    <h1 class="uk-text-lead">Header</h1>
                </div>
                <div class="uk-margin">
                    <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sequi, corporis voluptatibus? Odit,
                        quo repellat. Sequi distinctio dolores tenetur culpa nulla odio consequuntur cupiditate. Porro,
                        voluptas. Quasi doloribus minima voluptate et.
                    </p>
                </div>
            </div>

            <div class="uk-text-center">
                <div class="uk-margin">
                    <img class="uk-border-rounded" src="https://placehold.it/256x256" alt="">
                </div>
                <div class="uk-margin">
                    <h1 class="uk-text-lead">Business</h1>
                </div>
                <div class="uk-margin">
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. A repellat nobis corrupti eius
                        praesentium qui. Velit dignissimos voluptas commodi. Laborum omnis, mollitia ratione soluta
                        impedit tempora voluptate commodi quod recusandae.
                    </p>
                </div>
            </div>

            <div class="uk-text-center">
                <div class="uk-margin">
                    <img class="uk-border-rounded" src="https://placehold.it/256x256" alt="">
                </div>
                <div class="uk-margin">
                    <h1 class="uk-text-lead">Header</h1>
                </div>
                <div class="uk-margin">
                    <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Non architecto sed, soluta doloremque
                        aperiam aliquid animi consequuntur ipsum rem aut, esse necessitatibus cum maiores voluptates,
                        ullam fuga sapiente et pariatur.
                    </p>
                </div>
            </div>

            <div class="uk-text-center">
                <div class="uk-margin">
                    <img class="uk-border-rounded" src="https://placehold.it/256x256" alt="">
                </div>
                <div class="uk-margin">
                    <h1 class="uk-text-lead">Header</h1>
                </div>
                <div class="uk-margin">
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis labore quae ducimus, perferendis
                        debitis quasi voluptatibus illo, ipsa inventore eaque eius cum voluptatem fugit, et amet non.
                        Natus, tempora aspernatur.
                    </p>
                </div>
            </div>

        </div>
    </div>
</div>