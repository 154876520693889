import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-for-students-page',
  templateUrl: './for-students-page.component.html',
  styleUrls: ['./for-students-page.component.scss']
})
export class ForStudentsPageComponent implements OnInit {
  ngOnInit (): void {
  }
}
