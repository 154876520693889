<div class="uk-section">
    <div class="uk-container">

        <div>
            <h1 class="uk-heading-large">Terms of Service</h1>
        </div>

    </div>
</div>

<div class="uk-section">
    <div class="uk-container">

        <h2 class="uk-heading-small">1. Personal Information</h2>
        <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur suscipit repellendus quod nihil nostrum
            nemo, perspiciatis reprehenderit porro maxime accusantium commodi quae non sed corporis dolores distinctio
            odio fugit obcaecati! Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo inventore doloribus
            ullam necessitatibus! Similique modi tempora sequi tempore labore dolorem suscipit maxime, corrupti, alias
            cumque eius distinctio quod, nesciunt fugit! Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam
            aliquam, fugiat officia consectetur nulla pariatur officiis tempora sed eligendi veniam eius ab cupiditate
            quas quisquam, corrupti suscipit rem debitis. Debitis.
        </p>

        <h2 class="uk-heading-small">2. Application Data</h2>
        <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur suscipit repellendus quod nihil nostrum
            nemo, perspiciatis reprehenderit porro maxime accusantium commodi quae non sed corporis dolores distinctio
            odio fugit obcaecati! Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quia dolore amet commodi.
            Asperiores, fugit? Perspiciatis, corrupti possimus labore quas eos adipisci eaque? Repellendus quod possimus
            iure incidunt expedita nemo ipsum? Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo porro vero
            fugit suscipit? Dolor eligendi ipsam blanditiis nobis delectus, officiis voluptatem recusandae voluptas
            porro quia? Ipsum doloremque perspiciatis fugiat obcaecati. Lorem ipsum, dolor sit amet consectetur
            adipisicing elit. Cum maiores eligendi sapiente. In officiis recusandae modi aperiam reiciendis quo corporis
            voluptatem perspiciatis, minus maxime beatae fugiat, laborum quis, cum dolores.
        </p>

        <h2 class="uk-heading-small">3. Third-Party</h2>
        <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur suscipit repellendus quod nihil nostrum
            nemo, perspiciatis reprehenderit porro maxime accusantium commodi quae non sed corporis dolores distinctio
            odio fugit obcaecati!
        </p>

    </div>
</div>