import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { CommunityGuidelinesPageComponent } from './community-guidelines-page/community-guidelines-page.component'
import { FeaturesPageComponent } from './features-page/features-page.component'
import { ForIndividualsPageComponent } from './for-individuals-page/for-individuals-page.component'
import { ForGovernmentPageComponent } from './for-government-page/for-government-page.component'
import { ForCompaniesPageComponent } from './for-companies-page/for-companies-page.component'
import { GettingStartedPageComponent } from './getting-started-page/getting-started-page.component'
import { HomePageComponent } from './home-page/home-page.component'
import { LayoutComponent } from './layout/layout.component'
import { MerchantAgreementPageComponent } from './merchant-agreement-page/merchant-agreement-page.component'
import { PricingPageComponent } from './pricing-page/pricing-page.component'
import { PrivacyPolicyPageComponent } from './privacy-policy-page/privacy-policy-page.component'
import { RefundPolicyPageComponent } from './refund-policy-page/refund-policy-page.component'
import { TermsOfUsePageComponent } from './terms-of-use-page/terms-of-use-page.component'
import { TrademarkAndCopyrightPolicyPageComponent } from './trademark-and-copyright-policy-page/trademark-and-copyright-policy-page.component'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/'
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: HomePageComponent,
        data: {
          title: 'Home | fieldt.rip'
        }
      },
      {
        path: 'community-guidelines',
        component: CommunityGuidelinesPageComponent,
        data: {
          title: 'Community Guidelines | fieldt.rip'
        }
      },
      {
        path: 'features',
        component: FeaturesPageComponent,
        data: {
          title: 'Features | fieldt.rip'
        }
      },
      {
        path: 'individuals',
        component: ForIndividualsPageComponent,
        data: {
          title: 'For Individuals | fieldt.rip'
        }
      },
      {
        path: 'government',
        component: ForGovernmentPageComponent,
        data: {
          title: 'For Government | fieldt.rip'
        }
      },
      {
        path: 'companies',
        component: ForCompaniesPageComponent,
        data: {
          title: 'For Companies | fieldt.rip'
        }
      },
      {
        path: 'getting-started',
        component: GettingStartedPageComponent,
        data: {
          title: 'Getting Started | fieldt.rip'
        }
      },
      {
        path: 'merchant-agreement',
        component: MerchantAgreementPageComponent,
        data: {
          title: 'Merchant Agreement | fieldt.rip'
        }
      },
      {
        path: 'non-profits',
        component: PricingPageComponent,
        data: {
          title: 'For Non-Profits | fieldt.rip'
        }
      },
      {
        path: 'pricing',
        component: PricingPageComponent,
        data: {
          title: 'Pricing | fieldt.rip'
        }
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyPageComponent,
        data: {
          title: 'Privacy | fieldt.rip'
        }
      },
      {
        path: 'refund-policy',
        component: RefundPolicyPageComponent,
        data: {
          title: 'Refund Policy | fieldt.rip'
        }
      },
      {
        path: 'trademark-and-copyright-policy',
        component: TrademarkAndCopyrightPolicyPageComponent,
        data: {
          title: 'Trademark and Copyright Policy | fieldt.rip'
        }
      },
      {
        path: 'terms-of-service',
        component: TermsOfUsePageComponent,
        data: {
          title: 'Terms of Service | fieldt.rip'
        }
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
