import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { AppRoutingModule } from './app-routing.module'

import { AppComponent } from './app.component'
import { CommunityGuidelinesPageComponent } from './community-guidelines-page/community-guidelines-page.component'
import { CoursePageComponent } from './course-page/course-page.component'
import { FeaturesPageComponent } from './features-page/features-page.component'
import { FooterComponent } from './footer/footer.component'
import { ForCompaniesPageComponent } from './for-companies-page/for-companies-page.component'
import { ForGovernmentPageComponent } from './for-government-page/for-government-page.component'
import { ForNonProfitsPageComponent } from './for-non-profits-page/for-non-profits-page.component'
import { ForIndividualsPageComponent } from './for-individuals-page/for-individuals-page.component'
import { ForStudentsPageComponent } from './for-students-page/for-students-page.component'
import { GettingStartedPageComponent } from './getting-started-page/getting-started-page.component'
import { HomePageComponent } from './home-page/home-page.component'
import { LayoutComponent } from './layout/layout.component'
import { MerchantAgreementPageComponent } from './merchant-agreement-page/merchant-agreement-page.component'
import { NavbarComponent } from './navbar/navbar.component'
import { PricingPageComponent } from './pricing-page/pricing-page.component'
import { PrivacyPolicyPageComponent } from './privacy-policy-page/privacy-policy-page.component'
import { TermsOfUsePageComponent } from './terms-of-use-page/terms-of-use-page.component'
import { RefundPolicyPageComponent } from './refund-policy-page/refund-policy-page.component'
import { TrademarkAndCopyrightPolicyPageComponent } from './trademark-and-copyright-policy-page/trademark-and-copyright-policy-page.component'

@NgModule({
  declarations: [
    AppComponent,
    CommunityGuidelinesPageComponent,
    CoursePageComponent,
    FeaturesPageComponent,
    FooterComponent,
    ForCompaniesPageComponent,
    ForGovernmentPageComponent,
    ForNonProfitsPageComponent,
    ForIndividualsPageComponent,
    ForStudentsPageComponent,
    GettingStartedPageComponent,
    HomePageComponent,
    LayoutComponent,
    MerchantAgreementPageComponent,
    NavbarComponent,
    PricingPageComponent,
    PrivacyPolicyPageComponent,
    TermsOfUsePageComponent,
    TrademarkAndCopyrightPolicyPageComponent,
    RefundPolicyPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
