import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-merchant-agreement-page',
  templateUrl: './merchant-agreement-page.component.html',
  styleUrls: ['./merchant-agreement-page.component.scss']
})
export class MerchantAgreementPageComponent implements OnInit {
  ngOnInit (): void {
  }
}
