import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-trademark-and-copyright-policy-page',
  templateUrl: './trademark-and-copyright-policy-page.component.html',
  styleUrls: ['./trademark-and-copyright-policy-page.component.scss']
})
export class TrademarkAndCopyrightPolicyPageComponent implements OnInit {
  ngOnInit (): void {
  }
}
