<div class="uk-section uk-section-xlarge">
    <div class="uk-container">

        <div class="uk-margin-xlarge">
            <h1 class="uk-heading-small uk-text-center">Features</h1>
        </div>

        <div class="uk-child-width-1-2@s" uk-grid>
            <div class="uk-text-center">
                <img class="uk-border-rounded" src="https://placehold.it/800x600"
                    alt="">
            </div>
            <div class="uk-flex-first@s uk-text-center">
                <h1 class="uk-heading-medium uk-margin-large-top">Analytics & Dashboard</h1>
                <p class="uk-text-large">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Facilis eum vero, ipsa, qui iusto, magni
                    velit
                    repellat debitis aut aliquid quas asperiores aperiam illo! Blanditiis itaque distinctio quas magni
                    perspiciatis?
                </p>
            </div>
        </div>
    </div>
</div>

<div class="uk-section">
    <div class="uk-container">
        <div class="uk-child-width-1-2@s" uk-grid>
            <div class="uk-text-center">
                <img class="uk-border-rounded" src="https://placehold.it/800x600"
                    alt="">
            </div>
            <div class="uk-flex-first@s uk-text-center">
                <h1 class="uk-heading-medium uk-margin-large-top">Billing & Payments</h1>
                <p class="uk-text-large">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Facilis eum vero, ipsa, qui iusto, magni
                    velit
                    repellat debitis aut aliquid quas asperiores aperiam illo! Blanditiis itaque distinctio quas magni
                    perspiciatis?
                </p>
            </div>
        </div>
    </div>
</div>

<div class="uk-section">
    <div class="uk-container">
        <div class="uk-child-width-1-2@s" uk-grid>
            <div class="uk-text-center">
                <img class="uk-border-rounded" src="https://placehold.it/800x600"
                    alt="">
            </div>
            <div class="uk-flex-first@s uk-text-center">
                <h1 class="uk-heading-medium uk-margin-large-top">Course Registration & Management</h1>
                <p class="uk-text-large">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Facilis eum vero, ipsa, qui iusto, magni
                    velit
                    repellat debitis aut aliquid quas asperiores aperiam illo! Blanditiis itaque distinctio quas magni
                    perspiciatis?
                </p>
            </div>
        </div>
    </div>
</div>

<div class="uk-section">
    <div class="uk-container">
        <div class="uk-child-width-1-2@s" uk-grid>
            <div class="uk-text-center">
                <img class="uk-border-rounded" src="https://placehold.it/800x600"
                    alt="">
            </div>
            <div class="uk-flex-first@s uk-text-center">
                <h1 class="uk-heading-medium uk-margin-large-top">Family Member & Student Engagement</h1>
                <p class="uk-text-large">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Facilis eum vero, ipsa, qui iusto, magni
                    velit
                    repellat debitis aut aliquid quas asperiores aperiam illo! Blanditiis itaque distinctio quas magni
                    perspiciatis?
                </p>
            </div>
        </div>
    </div>
</div>

<div class="uk-section">
    <div class="uk-container">
        <div class="uk-child-width-1-2@s" uk-grid>
            <div class="uk-text-center">
                <img class="uk-border-rounded" src="https://placehold.it/800x600"
                    alt="">
            </div>
            <div class="uk-flex-first@s uk-text-center">
                <h1 class="uk-heading-medium uk-margin-large-top">Organization & Instructor Management</h1>
                <p class="uk-text-large">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Facilis eum vero, ipsa, qui iusto, magni
                    velit
                    repellat debitis aut aliquid quas asperiores aperiam illo! Blanditiis itaque distinctio quas magni
                    perspiciatis?
                </p>
            </div>
        </div>
    </div>
</div>

<div class="uk-section">
    <div class="uk-container">
        <div class="uk-child-width-1-2@s" uk-grid>
            <div class="uk-text-center">
                <img class="uk-border-rounded" src="https://placehold.it/800x600"
                    alt="">
            </div>
            <div class="uk-flex-first@s uk-text-center">
                <h1 class="uk-heading-medium uk-margin-large-top">Profile & Security</h1>
                <p class="uk-text-large">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Facilis eum vero, ipsa, qui iusto, magni
                    velit
                    repellat debitis aut aliquid quas asperiores aperiam illo! Blanditiis itaque distinctio quas magni
                    perspiciatis?
                </p>
            </div>
        </div>
    </div>
</div>