<div class="uk-section uk-section-muted uk-hidden">
    <div class="uk-container">
        <div class="uk-child-width-1-2@s uk-flex-center uk-child-width-1-3@s uk-child-width-1-4@m" uk-grid>
            <div>
                <h3>Getting Started</h3>
                <ul class="uk-list">
                    <li><a routerLink="/features">Getting Started</a></li>
                    <li><a routerLink="/features">Features</a></li>
                    <li><a routerLink="/pricing">Pricing</a></li>
                </ul>
            </div>
            <div>
                <h3>References</h3>
                <ul class="uk-list">
                    <li><a href="https://guide.fieldt.rip" target="_blank">Guidebook</a></li>
                    <li><a href="https://help.fieldt.rip" target="_blank">Help Center</a></li>
                </ul>
            </div>
            <div>
                <h3>Legal</h3>
                <ul class="uk-list">
                    <li><a routerLink="/community-guidelines">Community Guidelines</a></li>
                    <li><a routerLink="/merchant-agreement">Merchant Agreement</a></li>
                    <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                    <li><a routerLink="/refund-policy">Refund Policy</a></li>
                    <li><a routerLink="/trademark-and-copyright-policy">Trademark and Copyright Policy</a></li>
                    <li><a routerLink="/terms-of-service">Terms of Service</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="uk-text-center uk-margin-medium">
    <div>
        <img class="uk-width-small uk-border-rounded"
            src="https://upload.wikimedia.org/wikipedia/commons/a/a4/Flag_of_the_United_States.svg" alt="">
    </div>
    <div class="uk-margin-medium-top">
        <span class="uk-text-small">Copyright © <a href="https://fieldt.rip">fieldt.rip</a>.
            All Rights
            Reserved.</span>
    </div>
</div>