import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-refund-policy-page',
  templateUrl: './refund-policy-page.component.html',
  styleUrls: ['./refund-policy-page.component.scss']
})
export class RefundPolicyPageComponent implements OnInit {
  ngOnInit (): void {
  }
}
