<div class="uk-section uk-section-xlarge uk-background-cover uk-background-top-center uk-background-no-repeat"
    style="background-image:url('/assets/images/hero@2x.png');" uk-height-viewport="offset-top:true;offset-bottom:20;">
    <div class="uk-container">
        <div class="uk-width-1-2@s uk-width-2-3@m uk-text-center uk-text-left@s">
            <div class="uk-margin-top-large">
                <h1 class="uk-heading-large uk-blend-multiply">Coming soon!</h1>
            </div>
            <div class="uk-margin uk-width-3-5@l">
                <p>
                    Our mission is to improve the current landscape of available educational opportunities for students by establishing a market out from under the weight of traditional institutional bureaucracy.
                </p>
            </div>
            <div class="uk-margin-medium-top uk-hidden">
                <a class="uk-button uk-border-rounded uk-button-primary" href="https://dev.app.fieldt.rip/sign-up"
                    target="_blank">GET STARTED</a>
            </div>
        </div>
    </div>
</div>
<div class="uk-section uk-section-large uk-hidden">
    <div class="uk-container">
        <div class="uk-flex uk-child-width-1-2@s uk-text-center" uk-grid>
            <div class="uk-flex-last@s uk-margin-medium-top">
                <h1 class="uk-heading-small">Analytics & Event Monitoring</h1>
                <ul class="uk-list uk-list-large">
                    <li>Make data-driven decisions using course engagement and enrollment data</li>
                    <li>Receive real-time course enrollment and activity updates</li>
                    <li>Track changes to family members and student profiles to ensure accuracy</li>
                </ul>
            </div>
            <div class="uk-text-center">
                <img class="uk-width-auto uk-height-max-large" src="/assets/images/chalkboard@2x.png" alt="">
            </div>
        </div>
    </div>
</div>
<div class="uk-section uk-section-large uk-hidden">
    <div class="uk-container">
        <div class="uk-flex uk-child-width-1-2@s uk-text-center" uk-grid>
            <div>
                <h1 class="uk-heading-small">Organization Management & Payment Processing
                </h1>
                <ul class="uk-list uk-list-large">
                    <li>All of the essentials necessary to start and grow your business</li>
                    <li>Simple one-time and recurring course scheduling</li>
                    <li>Assign and monitor one or more instructors to courses </li>
                    <li>Accept course tuition and fees</li>
                    <li>Access tax forms for reporting purposes</li>
                </ul>
            </div>
            <div class="uk-text-center">
                <img class="uk-width-auto uk-height-max-large" src="/assets/images/selling@2x.png" alt="">
            </div>
        </div>
    </div>
</div>
<div class="uk-section uk-section-large uk-hidden">
    <div class="uk-container">
        <div class="uk-flex uk-child-width-1-2@s uk-text-center" uk-grid>
            <div class="uk-flex-last@s">
                <h1 class="uk-heading-small">Family Engagement</h1>
                <ul class="uk-list uk-list-large">
                    <li>Connect with students and families regarding course/class schedules and information via SMS and
                        email</li>
                    <li>Track current and new customers with Customer Relationship Management tool (CRM) integration?
                    </li>
                    <li>Develop attractive marketing campaigns with (Mailchimp?) integration</li>
                </ul>
            </div>
            <div class="uk-text-center">
                <img class="uk-width-auto uk-height-max-large" src="/assets/images/opening-mail@2x.png" alt="">
            </div>
        </div>
    </div>
</div>
<div class="uk-section uk-section-primary uk-section-large uk-hidden">
    <div class="uk-container">
        <div class="uk-child-width-1-2@s" uk-grid>
            <div class="uk-text-center uk-text-left@s">
                <h1 class="uk-heading-large">All of the Essentials</h1>
                <p class="uk-text-large">Everything you need to manage courses and begin receiving payments today.</p>
            </div>
            <div class="uk-text-center">
                <div class="uk-margin">
                    <h1 style="font-size:6rem;">$28</h1>
                </div>
                <div class="uk-margin">
                    <span class="uk-display-block uk-text-meta uk-text-uppercase uk-text-large">/month + 2%</span>
                </div>
                <div class="uk-margin-medium-top">
                    <a class="uk-button uk-border-rounded uk-button-primary" href="https://dev.app.fieldt.rip/sign-up"
                        target="_blank">GET STARTED</a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="uk-section uk-section-large uk-hidden">
    <div class="uk-container">
        <div class="uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-large" uk-grid uk-height-match>
            <div class="uk-text-center">
                <div>
                    <img class="uk-width-1-2" src="/assets/images/analytics@2x.png" alt="">
                </div>
                <div>
                    <h1 class="uk-text-lead">Analytics & Dashboard</h1>
                    <ul class="uk-list uk-list-large">
                        <li>Email & SMS w/ Family Members</li>
                        <li>Enroll Students</li>
                        <li>Process Payments</li>
                    </ul>
                </div>
            </div>
            <div class="uk-text-center">
                <div>
                    <img class="uk-width-1-2" src="/assets/images/exchanging-money@2x.png" alt="">
                </div>
                <div>
                    <h1 class="uk-text-lead">Billing & Payments</h1>
                    <ul class="uk-list uk-list-large">
                        <li>Email & SMS w/ Family Members</li>
                        <li>Enroll Students</li>
                        <li>Process Payments</li>
                    </ul>
                </div>
            </div>
            <div class="uk-text-center">
                <div>
                    <img class="uk-width-1-2" src="/assets/images/users@2x.png" alt="">
                </div>
                <div>
                    <h1 class="uk-text-lead">Organization & Instructor Management</h1>
                    <ul class="uk-list uk-list-large">
                        <li>Email & SMS w/ Family Members</li>
                        <li>Enroll Students</li>
                        <li>Process Payments</li>
                    </ul>
                </div>
            </div>
            <div class="uk-text-center">
                <div>
                    <img class="uk-width-1-2" src="/assets/images/paperwork@2x.png" alt="">
                </div>
                <div>
                    <h1 class="uk-text-lead">Course Registration & Management</h1>
                    <ul class="uk-list uk-list-large">
                        <li>Email & SMS w/ Family Members</li>
                        <li>Enroll Students</li>
                        <li>Process Payments</li>
                    </ul>
                </div>
            </div>
            <div class="uk-text-center">
                <div>
                    <img class="uk-width-1-2" src="/assets/images/mail@2x.png" alt="">
                </div>
                <div>
                    <h1 class="uk-text-lead">Family Member & Student Engagement</h1>
                    <ul class="uk-list uk-list-large">
                        <li>Email & SMS w/ Family Members</li>
                        <li>Enroll Students</li>
                        <li>Process Payments</li>
                    </ul>
                </div>
            </div>
            <div class="uk-text-center">
                <div>
                    <img class="uk-width-1-2" src="/assets/images/lock@2x.png" alt="">
                </div>
                <div>
                    <h1 class="uk-text-lead">Profile & Security</h1>
                    <ul class="uk-list uk-list-large">
                        <li>Email & SMS w/ Family Members</li>
                        <li>Enroll Students</li>
                        <li>Process Payments</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
