<div class="uk-section uk-section-xlarge">
    <div class="uk-container">

        <div class="uk-text-center uk-margin-xlarge">
            <h1 class="uk-heading-small">Getting Started</h1>
        </div>

        <div class="uk-child-width-1-2@s uk-margin-xlarge-top" uk-grid>
            <div>
                <h1 class="uk-heading-medium">1. Create Account</h1>
                <p class="uk-text-large">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Facere iure voluptatem, magnam delectus
                    dolores rem illum possimus, laudantium sapiente similique maxime et animi architecto, illo assumenda
                    pariatur ea blanditiis porro.</p>
            </div>
            <div>
                <img class="uk-border-rounded" src="https://placehold.it/800x600" alt="">
            </div>
        </div>

        <div class="uk-child-width-1-2@s uk-margin-xlarge-top" uk-grid>
            <div>
                <h1 class="uk-heading-medium">2. Complete Application</h1>
                <p class="uk-text-large">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Facere iure voluptatem, magnam delectus
                    dolores rem illum possimus, laudantium sapiente similique maxime et animi architecto, illo assumenda
                    pariatur ea blanditiis porro.</p>
            </div>
            <div>
                <img class="uk-border-rounded" src="https://placehold.it/800x600" alt="">
            </div>
        </div>

        <div class="uk-child-width-1-2@s uk-margin-xlarge-top" uk-grid>
            <div>
                <h1 class="uk-heading-medium">3. Add Courses and Instructors</h1>
                <p class="uk-text-large">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Facere iure voluptatem, magnam delectus
                    dolores rem illum possimus, laudantium sapiente similique maxime et animi architecto, illo assumenda
                    pariatur ea blanditiis porro.</p>
            </div>
            <div>
                <img class="uk-border-rounded" src="https://placehold.it/800x600" alt="">
            </div>
        </div>

        <div class="uk-child-width-1-2@s uk-margin-xlarge-top" uk-grid>
            <div>
                <h1 class="uk-heading-medium">4. Invite Family Members</h1>
                <p class="uk-text-large">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Facere iure voluptatem, magnam delectus
                    dolores rem illum possimus, laudantium sapiente similique maxime et animi architecto, illo assumenda
                    pariatur ea blanditiis porro.</p>
            </div>
            <div>
                <img class="uk-border-rounded" src="https://placehold.it/800x600" alt="">
            </div>
        </div>

        <div class="uk-child-width-1-2@s uk-margin-xlarge-top" uk-grid>
            <div>
                <h1 class="uk-heading-medium">5. Deliver Instruction</h1>
                <p class="uk-text-large">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Facere iure voluptatem, magnam delectus
                    dolores rem illum possimus, laudantium sapiente similique maxime et animi architecto, illo assumenda
                    pariatur ea blanditiis porro.</p>
            </div>
            <div>
                <img class="uk-border-rounded" src="https://placehold.it/800x600" alt="">
            </div>
        </div>

        <div class="uk-child-width-1-2@s uk-margin-xlarge-top" uk-grid>
            <div>
                <h1 class="uk-heading-medium">5. Receive Payment</h1>
                <p class="uk-text-large">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Facere iure voluptatem, magnam delectus
                    dolores rem illum possimus, laudantium sapiente similique maxime et animi architecto, illo assumenda
                    pariatur ea blanditiis porro.</p>
            </div>
            <div>
                <img class="uk-border-rounded" src="https://placehold.it/800x600" alt="">
            </div>
        </div>

    </div>
</div>