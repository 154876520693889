import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-course-page',
  templateUrl: './course-page.component.html',
  styleUrls: ['./course-page.component.scss']
})
export class CoursePageComponent implements OnInit {
  ngOnInit (): void {
  }
}
