import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-community-guidelines-page',
  templateUrl: './community-guidelines-page.component.html',
  styleUrls: ['./community-guidelines-page.component.scss']
})
export class CommunityGuidelinesPageComponent implements OnInit {
  ngOnInit (): void {
  }
}
