import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-for-companies-page',
  templateUrl: './for-companies-page.component.html',
  styleUrls: ['./for-companies-page.component.scss']
})
export class ForCompaniesPageComponent implements OnInit {
  ngOnInit (): void {
  }
}
