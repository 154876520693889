import { Component } from '@angular/core'
import { IntercomService } from './intercom.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor (private readonly intercomService: IntercomService) { }
  ngOnInit (): void {
    this.intercomService.boot()
  }
}
