<div
    uk-sticky="animation:uk-animation-slide-top;sel-target:.uk-navbar-container;cls-active:uk-navbar-sticky;cls-inactive:uk-navbar-transparent; top:200">
    <nav class="uk-navbar-container uk-width-expand uk-position-fixed uk-display-block">
        <div class="uk-container uk-container-expand">
            <div uk-navbar>
                <div class="uk-navbar-left">
                    <div class="uk-navbar-item">
                        <a href="/" class="uk-logo">fieldt.rip</a>
                    </div>
                </div>
                <div class="uk-navbar-center uk-visible@s uk-hidden">
                    <ul class="uk-navbar-nav">
                        <li><a routerLink="/getting-started">Getting Started</a></li>
                        <li><a href="/features">Features</a></li>
                        <li><a href="/pricing">Pricing</a></li>
                    </ul>
                </div>
                <div class="uk-navbar-right uk-hidden">
                    <ul class="uk-navbar-nav">
                        <li><a href="https://dev.app.fieldt.rip/sign-in" target="_blank"
                                uk-icon="icon:sign-in;ratio:1.35;"></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</div>